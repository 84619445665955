<template>
  <div>
    <multiple-position-edit />
  </div>
</template>
<script>
import MultiplePositionEdit from "../components/MultiplePositionEdit";

export default {
  components: {
    MultiplePositionEdit
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
