import gql from "graphql-tag";

export const UPDATE_MULTIPLE_POS = gql`
  mutation updateMultiplePos($fieldId:ID!,$input:[MultiplePosInput!]){
    updateMultiplePos(fieldId:$fieldId,input:$input){
      multiplePos{
        id
        field{
          id
        }
        lat
        lon
        wayType
        routeNumber
      }
    }
  }
`;

export const DELETE_MULTIPLE_POS = gql`
  mutation deleteMultiplePos($fieldId:ID!){
    deleteMultiplePos(fieldId:$fieldId){
      multiplePos{
        id
        field{
          id
        }
        lat
        lon
        wayType
        routeNumber
      }
    }
  }
`;
