import gql from "graphql-tag";

export const MULTIPLE_POS_QUERY = gql`
  query($fieldId:ID!){
    multiplePos(fieldId:$fieldId,orderBy:"route_number"){
      edges{
        node{
          id
          lat
          lon
          wayType
          routeNumber
        }
      }
    }
  }
`;
