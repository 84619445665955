<template>
  <div>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>ルート番号</b-th>
          <b-th>緯度</b-th>
          <b-th>経度</b-th>
          <b-th>経路</b-th>
          <b-th>削除</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(list,index) in this.multiplePos"
          :key="list.id"
          draggable
          @dragstart="dragList($event, index)"
          @drop="dropList($event, index,multiplePos)"
          @dragover.prevent
          @dragenter.prevent
        >
          <b-td>{{list.routeNumber}}</b-td>
          <b-td>{{list.lat}}</b-td>
          <b-td>{{list.lon}}</b-td>
          <b-td>{{list.wayType}}</b-td>
          <b-td>
            <b-button
              type="submit"
              size="sm"
              variant="danger"
              class="icon-trash"
              v-on:click="deleteMultiplePos(multiplePos,index)"
            ></b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-form-group label="緯度">
          <b-form-input type="text" v-model="fieldPos.lat"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="経度">
          <b-form-input type="text" v-model="fieldPos.lon"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <b-button
            type="submit"
            size="sm"
            variant="outline-success"
            v-on:click="currentPosition()"
            style="position: absolute; top: 30px;"
          >
            <i class="fa fa-dot-circle-o"></i> 現在位置取得
          </b-button>
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          type="button"
          variant="primary"
          size="sm"
          style="position: absolute; top: 30px;"
          v-on:click="createMultiplePos()"
        >新規作成</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { UPDATE_MULTIPLE_POS } from "../../components/multiple-pos-mutation";
import { getPosErrorMsg } from "../../components/message";

const ROW_START = 1;
const ROW_END = 2;

export default {
  data() {
    return {
      multiplePos: [],
      otherMultiplePos: [],
      fieldPos: { lat: null, lon: null }
    };
  },
  props: {
    fieldId: {
      default: null
    },
    fieldMultiplePos: {
      type: Array,
      default: () => []
    },
    stationMultiplePos: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    fieldMultiplePos: function() {
      this.multiplePos = this.fieldMultiplePos.slice();
    },
    stationMultiplePos: function() {
      let start = this.stationMultiplePos.start.slice();
      let end = this.stationMultiplePos.end.slice();
      this.otherMultiplePos = start.concat(end);
    }
  },
  methods: {
    dragList: function(event, dragIndex) {
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex);
    },
    dropList: function(event, dropIndex, multiplePos) {
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = multiplePos.splice(dragIndex, 1);
      multiplePos.splice(dropIndex, 0, deleteList[0]);
      this.updateMultiplePos();
    },
    sortFieldMultiplePos: function() {
      this.multiplePos.forEach((val, index) => {
        val.wayType = index % 2 == 0 ? ROW_START : ROW_END;
        val.routeNumber = index + 1;
      });
    },
    currentPosition: function() {
      let _this = this;
      navigator.geolocation.getCurrentPosition(
        function(position) {
          _this.fieldPos.lat = position.coords.latitude;
          _this.fieldPos.lon = position.coords.longitude;
        },
        function(error) {
          switch (error.code) {
            case 1:
              alert(getPosErrorMsg.PERMISSION);
              break;
            case 2:
              alert(getPosErrorMsg.FETCH);
              break;
            case 3:
              alert(getPosErrorMsg.TIME_OUT);
              break;
            default:
              alert(getPosErrorMsg.OTHER + error.code);
              break;
          }
        }
      );
    },
    createMultiplePos: function() {
      this.multiplePos.push({
        lat: this.fieldPos.lat,
        lon: this.fieldPos.lon
      });
      this.fieldPos = { lat: null, lon: null };

      this.updateMultiplePos();
    },
    updateMultiplePos: function() {
      this.sortFieldMultiplePos();
      let multiplePos = this.multiplePos.concat(this.otherMultiplePos);
      this.$apollo
        .mutate({
          mutation: UPDATE_MULTIPLE_POS,
          variables: {
            fieldId: this.fieldId,
            input: multiplePos
          }
        })
        .then(result => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    deleteMultiplePos: function(multiplePos, deleteIndex) {
      multiplePos.splice(deleteIndex, 1);
      this.updateMultiplePos();
    }
  }
};
</script>
