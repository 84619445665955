<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3>畑選択</h3>
        </b-col>
      </b-row>
      <field-form v-on:getField="setField"></field-form>
      <b-row>
        <b-col>
          <google-map
            ref="gmapObject"
            :propMapPos="{lat:field.lat,lng:field.lon}"
            :markers="markers"
            :stationMarkers="stationMarkers"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <h3>畑ルート</h3>
        </b-col>
      </b-row>
      <multiple-position-field-table
        :stationMultiplePos="stationMultiplePos"
        :fieldMultiplePos="fieldMultiplePos"
        :fieldId="field.id"
        @refetch="refetchMultiplePos"
      />
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <h3>ステーション開始ルート</h3>
        </b-col>
      </b-row>
      <multiple-position-station-table
        :dispStationMultiplePos="stationMultiplePos.start"
        :hideStationMultiplePos="stationMultiplePos.end"
        :fieldMultiplePos="fieldMultiplePos"
        :fieldId="field.id"
        :stationType="stationType.start"
        @refetch="refetchMultiplePos"
      />
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <h3>ステーション終了ルート</h3>
        </b-col>
      </b-row>
      <multiple-position-station-table
        :dispStationMultiplePos="stationMultiplePos.end"
        :hideStationMultiplePos="stationMultiplePos.start"
        :fieldMultiplePos="fieldMultiplePos"
        :fieldId="field.id"
        :stationType="stationType.end"
        @refetch="refetchMultiplePos"
      />
    </b-card>
  </div>
</template>

<script>
import { MULTIPLE_POS_QUERY } from "../../components/multiple-pos-query";
import { getPosErrorMsg } from "../../components/message";
import GoogleMap from "../components/GoogleMap";
import FieldForm from "./form/FieldForm";
import MultiplePositionStationTable from "../components/MultiplePositionStationTable";
import MultiplePositionFieldTable from "../components/MultiplePositionFieldTable";

const ROW_START = 1;
const ROW_END = 2;
const STATION_START = 3;
const STATION_END = 4;

export default {
  components: {
    FieldForm,
    GoogleMap,
    MultiplePositionStationTable,
    MultiplePositionFieldTable
  },
  data() {
    return {
      multiplePos: {},
      fieldMultiplePos: [],
      stationMultiplePos: { start: [], end: [] },
      field: {},
      markers: [],
      stationMarkers: { start: [], end: [] },
      stationType: {
        start: STATION_START,
        end: STATION_END
      }
    };
  },
  methods: {
    setField: function(value) {
      this.field = value;
      this.readMultiplePos();
    },
    readMultiplePos: function() {
      if (this.field) {
        this.$apollo.addSmartQuery("multiplePos", {
          query: MULTIPLE_POS_QUERY,
          variables: {
            fieldId: this.field.id
          },
          result({ data, load }) {
            if (data) {
              this.sortMultiplePos(data.multiplePos);
            }
          }
        });
      }
    },
    refetchMultiplePos: function() {
      this.$apollo.queries.multiplePos.refetch();
    },
    sortMultiplePos: function(multiplePos) {
      this.fieldMultiplePos = [];
      this.stationMultiplePos = { start: [], end: [] };
      this.markers = [];
      this.stationMarkers = { start: [], end: [] };
      for (let val of multiplePos.edges) {
        switch (val.node.wayType) {
          case ROW_START:
          case ROW_END:
            this.fieldMultiplePos.push({
              lat: val.node.lat,
              lon: val.node.lon,
              wayType: val.node.wayType,
              routeNumber: val.node.routeNumber
            });
            this.markers.push({
              position: { lat: val.node.lat, lng: val.node.lon },
              routeNumber: val.node.routeNumber
            });
            break;
          case STATION_START:
            this.stationMultiplePos.start.push({
              lat: val.node.lat,
              lon: val.node.lon,
              wayType: val.node.wayType,
              routeNumber: val.node.routeNumber
            });
            this.stationMarkers.start.push({
              position: { lat: val.node.lat, lng: val.node.lon },
              routeNumber: val.node.routeNumber
            });
            break;
          case STATION_END:
            this.stationMultiplePos.end.push({
              lat: val.node.lat,
              lon: val.node.lon,
              wayType: val.node.wayType,
              routeNumber: val.node.routeNumber
            });
            this.stationMarkers.end.push({
              position: { lat: val.node.lat, lng: val.node.lon },
              routeNumber: val.node.routeNumber
            });
            break;
          default:
            break;
        }
      }
    }
  }
};
</script>
